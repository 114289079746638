<template>
  <div class="card-type-img">
    <div class="content">
      <h2>
        <span
          v-if="pastille"
          :class="pastille.theme"
        >
          {{ pastille.text }}
        </span>
        {{ title }}
      </h2>
      <slot name="content"/>
    </div>
    <figure class="img">
      <slot name="img"/>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'card-type-img',
  props: {
    pastille: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style lang="sass" scoped>
.card-type-img
  display: flex
  flex-direction: row
  align-items: center
  background-color: $white
  border-radius: $global-border-radius
  box-shadow: $drop-shadow
  height: 100%
  position: relative

  .content
    width: calc(60% - 80px*2)
    padding: 80px

    h2
      margin-bottom: 38px

      span
        display: inline-block
        min-width: 50px
        min-height: 50px
        border-radius: 50%
        font-size: 36px
        font-weight: 500
        color: $off-white
        margin-right: 8px
        line-height: 50px
        text-align: center

      .blue
        background: $pastille-blue

      .red
        background: $pastille-red

      .yellow
        background: $pastille-yellow

      .green
        background: $pastille-green

    blockquote
      color: $primary
      margin: 0 0 35px 0
      line-height: 1.4

    p, li
      a
        color: $primary
        text-decoration: none
        font-weight: bold
        font-size: inherit
    ul
      padding: 0.3rem 0 0 0
      margin-top: 25px
      &:not(:last-child)
        margin-bottom: 25px
      li
        padding: 0.3rem 0 0.3rem 32px
        list-style-type: none
        text-align: left
        line-height: 1.4
        position: relative
        &::before
          content: url('../../assets/img/arrow-blue.svg')
          position: absolute
          top: 7px
          left: 0
          width: 20px
          height: 18px
          margin-right: 8px

  .img
    width: 40%
    height: 100%
    position: absolute
    top: 0
    right: 0

    img
      display: block
      object-fit: cover
      width: 100%
      height: 100%
      border-radius: $global-border-radius

  @media (max-width: 768px)
    flex-direction: column-reverse
    padding: 28px 24px
    height: auto

    .content
      width: auto
      figure
        display: flex
        justify-content: center
      blockquote
        margin: 0 0 25px 0

    .content,
    .img
      padding: 0

    .img,
    .content h2
      margin-bottom: 24px

    .img
      position: initial
      width: 100%
      img
        margin: 0 auto
        max-height: 300px
        width: 100%
        height: auto
</style>
